import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [0];

export const dictionary = {
		"/": [3],
		"/commercial-form/[slug]": [15],
		"/commercial-new": [16],
		"/(app)/inventory": [4,[2]],
		"/(app)/purchase-orders": [5,[2]],
		"/(app)/quotes": [6,[2]],
		"/(app)/quotes/[slug]": [7,[2]],
		"/(app)/reports/rfqs": [8,[2]],
		"/(app)/reports/sales-orders": [9,[2]],
		"/rfq-public/[slug]": [18],
		"/(app)/rfqs": [~10,[2]],
		"/(app)/rfqs/[slug]": [11,[2]],
		"/rfq/[slug]": [17],
		"/(app)/sales/commercial": [12,[2]],
		"/(app)/sales/government": [13,[2]],
		"/settings": [19],
		"/solicitation-form/[slug]": [21],
		"/solicitation/[slug]": [20],
		"/(app)/workflows": [14,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';